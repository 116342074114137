<template>
  <v-app>
    <v-banner
        single-line
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/h5-banner-wangqingyun.png"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0">
          <v-card color="transparent banner-text" class="pl-6 pt-15" flat>
            <v-card-title class="white--text font-size-32 font-size-banner-title font-weight-bold px-0 pa-0">
              万擎云
            </v-card-title>
            <v-card-title class="white--text font-size-14 font-size-banner-tip px-0" style="line-height:25px">
              轻量易用的跨平台3D <br> 编辑工具
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>

    <!-- 核心优势 -->
    <v-card class="warp1 px-4 py-6" flat>
      <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">核心优势</v-card-title>
      <div class="mt-4">
        <v-row v-for="(row,rowIndex) in warp1" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card" min-height="90" width="100%" flat>
            <v-img :src="row.icon" max-width="67" max-height="67" contain position="top"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-1 font-size-16 font-weight-bold line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.explain}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>

    <!-- 核心功能 -->
    <v-card color="#2181EA" flat class="warp2 py-6 position-r">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-FFF pa-0 line-height-28">核心功能</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" color="white" dark
              class="xs-tab">
        <v-tab v-for="(v, k) in warp2" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === warp2.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-FFF font-size-16 letter-spacing">{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in warp2" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold line-height-25">{{v.lunTitle}}</v-card-title>
            <v-card-text class="pa-0 mt-2 font-size-15 color-555 line-height-21" v-text="v.tip" />
            <div class="mt-3 font-size-13 color-666">
              <v-card-text class="pa-0 my-1 line-height-26" v-for="(i, j) in v.text" :key="j" v-text="i" />
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>

    <!-- 产品案例 -->
    <!--<v-card class=" py-6" flat>-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">产品案例</v-card-title>-->
    <!--  <div class="div-tabs">-->
    <!--    <v-tabs-items v-model="tab" class="new-tabs-items-h5" v-cloak>-->
    <!--      <v-tab-item-->
    <!--        v-model="tab"-->
    <!--        v-for="(v, k) in anli"-->
    <!--        :key="k"-->
    <!--      >-->
    <!--        <v-container fluid>-->
    <!--            <v-card-->
    <!--              class="mx-auto item-card"-->
    <!--              max-width="240"-->
    <!--              max-height="410"-->
    <!--              @click="goCaseDetail(v)"-->
    <!--            >-->
    <!--              <v-img-->
    <!--                max-height="340"-->
    <!--                class="tran-sec"-->
    <!--                :src="v.coverImg"-->
    <!--                :aspect-ratio="9/16"-->
    <!--              >-->
    <!--                <div class="text-box">-->
    <!--                  <v-card-text class="title" v-text="v.itemTitle"></v-card-text>-->
    <!--                </div>-->
    <!--              </v-img>-->
    <!--            </v-card>-->
    <!--        </v-container>-->
    <!--      </v-tab-item>-->
    <!--    </v-tabs-items>-->
    <!--  </div>-->
    <!--</v-card>-->

  </v-app>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      imgLoad: false,
      warp1: [
        {
          title: "图形化界面",
          icon: require("@/assets/images/icon/h5-wangqing-warp1-01.png"),
          explain: "图形化的编辑页面，让3D场景搭建、炫酷动画的制作变得简单",
        },
        {
          title: "低代码脚本",
          icon: require("@/assets/images/icon/h5-wangqing-warp1-02.png"),
          explain: "简单易用的预制接口，让用户通过少量代码即可写出优雅的脚本",
        },
        {
          title: "跨平台 多终端",
          icon: require("@/assets/images/icon/h5-wangqing-warp1-03.png"),
          explain: "支持安卓、IOS、H5小程序等多渠道同时发布，确保触达更多用户场景",
        },
      ],

      warp2: [
        {
          tabTitle: '3D场景搭建',
          img: 'https://h5.ophyer.cn/official_website/other/develop5-1new.png',
          lunTitle: '快速搭建3D场景',
          tip: '',
          text: [
            '平台预设的3D模型，搭配用户上传的贴图可以快速搭建业务场景\n所需要的3D场景',
          ]
        },
        {
          tabTitle: '动画制作',
          img: 'https://h5.ophyer.cn/official_website/other/WanqingClou-5-bg.png',
          lunTitle: '序列帧、粒子动画快速生成',
          tip: '',
          text: [
            '序列帧动画编辑工具可以快速制作序列帧动画',
            '内置粒子工具轻松制作粒子动画特效，让动画效果更上一层楼',
          ]
        },
        {
          tabTitle: '脚本自定义',
          img: 'https://h5.ophyer.cn/official_website/other/WanqingClou-6-bg.png',
          lunTitle: '预设API，场景表现更随心',
          tip: '',
          text: [
            '通过预设API控制场景元素运动及动画的播放，让场景的表现更随心',
          ]
        },
   
      ],
      anli:[textData.anli.mingshengyaoye,textData.anli.kislandwanju,textData.anli.paopaomate,textData.anli.wanshifujiaju], 
      tab: 0,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleTabBtn: function (type){
      const maxTab = this.warp2.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    },
    goCaseDetail(item) {
      this.$router.push({ 
        name: 'h5CaseDetail',
        query: {
          source: 'qita',
          anli: item.self
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  ::v-deep .v-responsive__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.new-tabs-items-h5 {
  margin-top: 20px;
  background: #fff !important;
}
</style>
